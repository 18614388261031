import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import '../styles/components/NotificationMessages.css';

class NotificationMessages extends Component {
  state = {
    isClosing: null
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ isClosing: true });
    }, 4000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { intl, messageKey, type } = this.props;
    const { isClosing } = this.state;
    const _ = id => intl.formatHTMLMessage({ id });

    return (
      <div className="NotificationMessages">
        <div className={`message ${type} ${isClosing ? 'close' : ''}`}>
          {_(messageKey)}
        </div>
      </div>
    );
  }
}

export default injectIntl(NotificationMessages);
