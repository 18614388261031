import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Chart from 'chart.js';
import moment from 'moment';
import styled from 'styled-components';

import colors from '../helpers/colors';
import { capitalize, disemvowel } from './MyFollowUpChart';

class HomeMyFollowUp extends Component {
  componentDidMount() {
    const canvas = document.querySelector('#myChart');
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    this.chart = new Chart(document.querySelector('#myChart'), {
      type: 'bar',
      data: {
        labels: this.getLabels(),
        datasets: this.getChartData()
      },
      options: {
        barShowStroke: false,
        hover: {
          animationDuration: 0
        },
        animation: {
          onComplete: function() {
            const chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(12, 700, 'Montserrat');
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                let data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              categoryPercentage: 0.6,
              barThickness: 12,
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#333333'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                max: 120, // Allow to display the number upon the chart bars
                display: false,
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }

  getLabels() {
    const { intl } = this.props;
    const _ = id => intl.formatHTMLMessage({ id });
    const mnt = moment();
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ].map(name =>
      disemvowel(_('week_' + name))
        .slice(0, 2)
        .toLowerCase()
    );

    return [
      [daysOfWeek[mnt.day()], mnt.format('DD')].reverse(),
      [daysOfWeek[mnt.subtract(1, 'days').day()], mnt.format('DD')].reverse(),
      [daysOfWeek[mnt.subtract(1, 'days').day()], mnt.format('DD')].reverse()
    ].reverse();
  }

  getChartData() {
    const { intl } = this.props;
    const { myFollowUpStats } = this.props;
    const _ = id => intl.formatHTMLMessage({ id });
    const datasets = [
      {
        label: _('stretchings'),
        backgroundColor: '#E86869',
        data: new Array(3).fill(0),
        borderWidth: 0
      },
      {
        label: _('trainings'),
        backgroundColor: '#CD8BB7',
        data: new Array(3).fill(0),
        borderWidth: 0
      }
    ];

    myFollowUpStats.forEach((category, i) => {
      category.data.forEach((val, j) => {
        datasets[i].data[j] = val > category.times[j] ? 100 : (val / category.times[j]) * 100;
      });
    });

    datasets.map(series => {
      series.data = series.data.map(val => Math.ceil(val));
      return series;
    });

    return datasets;
  }

  render() {
    const { intl } = this.props;
    const _ = id => intl.formatHTMLMessage({ id });

    return (
      <div>
        <MyFollowUp>
          <MyFollowUpWrapper>
            <canvas id="myChart" width="400" height="400" style={{ margin: '0 auto' }} />
            <Link to="/myFollowUp">
              <ActionButton>{_('details')}</ActionButton>
            </Link>
          </MyFollowUpWrapper>
        </MyFollowUp>
      </div>
    );
  }
}

export default injectIntl(HomeMyFollowUp);

const MyFollowUp = styled.div`
  position: relative;
  max-height: 400px;
  background: #ffffff;
  border-radius: 26px;
  padding: 20px;
  width: 100%;
`;

const MyFollowUpWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  color: #333333;

  &::before {
    content: '%';
    position: absolute;
    top: 25px;
    left: 15px;
    font-size: 13px;
  }
`;

const ActionButton = styled.div`
  margin-top: 20px;

  background: linear-gradient(270deg, #d5edf1 0%, #b5cfd3 100%), #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  width: 100%;

  font-weight: 600;
  font-size: 10px;
  line-height: 13px;

  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #333333;
`;
