import React, { useEffect, useState } from 'react';
import database from '../../helpers/database';
import moment from 'moment';

const useMyFollowUpStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myFollowUpStats, setMyFollowUpStats] = useState([]);

  const getAllPrescriptionExercises = async () => {
    const user = await database.app.get('user');

    let prescriptionExercises = [];

    if (user.Prescription && user.Prescription.length) {
      prescriptionExercises = user.Prescription.reduce((acc, prescription) => {
        return [...acc, ...prescription.PrescriptionExercise];
      }, []);
    }

    // Order by the most recent
    prescriptionExercises.sort((a, b) => {
      return moment(b.modified).unix() - moment(a.modified).unix();
    });

    return prescriptionExercises;
  };

  const getMyFollowUpStats = async () => {
    const stats = [
      {
        data: new Array(3).fill(0),
        times: new Array(3).fill(0)
      },
      {
        data: new Array(3).fill(0),
        times: new Array(3).fill(0)
      }
    ];
    const periods = [
      moment()
        .subtract(2, 'days')
        .format('YYYY-MM-DD'),
      moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ];
    const exercises = await database.exercise.toArray();
    const exerciseTypeIds = exercises.reduce((acc, item) => {
      acc[item.id] = item.exercise_type_id;
      return acc;
    }, {});
    const allPrescriptionExercises = await getAllPrescriptionExercises();
    const executionByExercise = {};

    allPrescriptionExercises.forEach(prescriptionExercise => {
      prescriptionExercise.PrescriptionExerciseExecution.forEach(exec => {
        const date = moment(exec.created).format('YYYY-MM-DD');

        if (!executionByExercise[date]) {
          if (!executionByExercise[date]) {
            executionByExercise[date] = {};
          }
        }

        if (executionByExercise[date][prescriptionExercise.exercise_id]) {
          executionByExercise[date][prescriptionExercise.exercise_id].count += 1;
        } else {
          const mostPrescriptionExercise = allPrescriptionExercises.reduce((acc, item) => {
            if (
              !acc &&
              prescriptionExercise.exercise_id === item.exercise_id &&
              moment(item.created, 'YYYY-MM-DD').isSame(moment(date))
            ) {
              return item;
            }

            return acc;
          }, null);

          executionByExercise[date][prescriptionExercise.exercise_id] = {
            exerciseType: Number(exerciseTypeIds[prescriptionExercise.exercise_id]) === 1 ? 0 : 1,
            count: 1,
            times: mostPrescriptionExercise
              ? mostPrescriptionExercise.times
              : Number(prescriptionExercise.times)
          };
        }
      });
    });

    periods.forEach((date, i) => {
      const prescriptionExercise = executionByExercise[date];

      if (prescriptionExercise) {
        Object.keys(prescriptionExercise).forEach(id => {
          const data = prescriptionExercise[id];

          if (data) {
            stats[data.exerciseType].data[i] += data.count;
            stats[data.exerciseType].times[i] += data.times;
          }
        });
      }
    });

    return stats;
  };

  const fetchMyFollowUpStats = async () => {
    const stats = await getMyFollowUpStats();

    setMyFollowUpStats(stats);
  };

  useEffect(() => {
    fetchMyFollowUpStats();
  }, []);

  return { myFollowUpStats, isLoading };
};

export default useMyFollowUpStats;
