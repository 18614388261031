import React from 'react';
import { useGlobalStore } from './../../stores/globalStore';
import useUser from './../../api/hooks/useUser';
import SideMenu from './SideMenu';
import HomeProfile from '../HomeProfile';
import HomeMyFollowUp from '../HomeMyFollowUp';

import useMyFollowUpStats from '../../api/hooks/useMyFollowUpStats';

const Sidebar = () => {
  const { myFollowUpStats } = useMyFollowUpStats();

  return (
    <div className="sidebar">
      <HomeProfile />
      <HomeMyFollowUp myFollowUpStats={myFollowUpStats} />
    </div>
  );
};

export default Sidebar;
