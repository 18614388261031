import colors from './colors';

export default class Utils {
  static navigatorLanguage(short = true) {
    let lang =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      (navigator.userLanguage &&
        navigator.userLanguage.replace(/-[a-z]{2}$/, String.prototype.toUpperCase)) ||
      'en_GB';

    //format navigator language (ex fr_FR)
    if (short) {
      lang = lang.substr(0, 2);
    } else {
      if (lang.length === 2) {
        lang = lang + '_' + lang.toUpperCase();
      } else {
        lang = lang.replace(/-/g, '_').replace(/_[a-z]{2}$/, function(v) {
          return v.toUpperCase();
        });
      }
    }

    return lang;
  }

  static isStorageAvailable(type) {
    let test;

    try {
      const storage = window[type],
        x = '__storage_test__';

      storage.setItem(x, x);
      storage.removeItem(x);
      test = true;
    } catch (e) {
      test = false;
    }

    return test;
  }

  /**
   * Prevent the displaying of the bubbles
   *
   * @param {element} form The form
   */
  static preventNativeValidationUI(form) {
    // Suppress the default bubbles
    form.addEventListener(
      'invalid',
      function(event) {
        event.preventDefault();
      },
      true
    );

    // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
    // form submissions by default
    form.addEventListener('submit', function(event) {
      if (!this.checkValidity()) {
        event.preventDefault();
      }
    });
  }

  /**
   * Managing of the error messages
   *
   * @param {element} form The form
   * @param {function} beforeCallback For example, that allows to define the input error messages with "setCustomValidity" method before the displaying
   */
  static replaceValidationUI(form, beforeCallback) {
    let validFields = form.querySelectorAll(':valid'),
      invalidFields = form.querySelectorAll(':invalid'),
      errorMessages = form.querySelectorAll('.error-message'),
      parent;

    // Remove any existing messages
    for (let i = 0; i < errorMessages.length; i++) {
      errorMessages[i].parentNode.removeChild(errorMessages[i]);
    }

    for (let i = 0; i < validFields.length; i++) {
      if (validFields[i].nodeName !== 'FIELDSET') {
        // validFields[i].style.border = 'none';
      }
    }

    for (let i = 0; i < invalidFields.length; i++) {
      if (invalidFields[i].nodeName !== 'FIELDSET') {
        invalidFields[i].style.border = '2px solid' + colors.blue;

        if (beforeCallback) {
          beforeCallback(invalidFields[i]);
        }

        parent = invalidFields[i].parentNode;
        parent.insertAdjacentHTML(
          'beforeend',
          "<span class='error-message'>" + invalidFields[i].validationMessage + '</span>'
        );
      }
    }
  }

  /**
   * Force the displaying of the error messages
   *
   * @param {element} form The form
   */
  static forceValidationUI(form) {
    const requiredFields = form.querySelectorAll(':required');

    for (let i = 0; i < requiredFields.length; i++) {
      requiredFields[i].parentNode.insertAdjacentHTML(
        'beforeend',
        "<span class='error-message'>" + requiredFields[i].validationMessage + '</span>'
      );
    }
  }

  static getElementOffset(element) {
    const rect = element.getBoundingClientRect();

    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    };
  }

  static truncate(text, length) {
    if (text.length <= length) {
      return text;
    }

    text = text.slice(0, length);

    if (text.slice(text.length - 1)) {
      text = text.slice(0, Math.min(text.length, text.lastIndexOf(' ')));
    }

    text += '...';

    return text;
  }

  static getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
}
