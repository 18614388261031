/**
 * Service for making all API requests to the IPSEN server
 */

import axios from 'axios';
import { parseString } from 'xml2js';
import qs from 'qs';

import { notificationObservable } from '../containers/NotificationMessagesContainer';
import Common from './Common';

export default class Requester {
  static fetch({
    url = null,
    headers = {},
    omitHeaders = [], // Name of the headers to omit
    withoutHeaders = false, // Clear the default headers
    params = {}, // params in the URL
    data = {}, // body of the request
    method = 'post',
    sync = false, // Tiny change of the API url, see below in the code
    raw = false, // Return the response without formatting
    isXMLResponse = false,
    responseType = 'text'
  }) {
    const transformRequest = [
      function(data) {
        return qs.stringify(data);
      }
    ];

    const defaultHeaders = withoutHeaders
      ? {}
      : {
          'X-API-CLIENT': process.env.REACT_APP_X_API_CLIENT,
          'X-Timezone-Offset': process.env.REACT_APP_X_TIMEZONE_OFFSET,
          'Content-Type': 'application/x-www-form-urlencoded'
        };

    const requestHeaders = {};

    if (process.env.REACT_APP_HEADER_AUTHORIZATION) {
      requestHeaders.Authorization = process.env.REACT_APP_HEADER_AUTHORIZATION;
    }

    omitHeaders.forEach(name => {
      delete defaultHeaders[name];
    });

    Object.assign(requestHeaders, defaultHeaders, headers);

    if (!url) {
      url = `https://${process.env.REACT_APP_API_URL}/wsu/${
        sync ? 'sync' : 'request'
      }`;
    }

    if (params.action !== 'token') {
      if (data.User) {
        data.User.token = localStorage.getItem('token');
      } else {
        data.User = { token: localStorage.getItem('token') };
      }
    }


    return axios({
      url,
      headers: requestHeaders,
      params,
      data,
      method,
      transformRequest,
      responseType
    })
      .then(function(response) {
        return new Promise(resolve => {
          if (isXMLResponse) {
            parseString(response.data, function(err, result) {
              resolve(result);
            });
          }

          // Save the session_id
          // if (response.data.data && response.data.data.session_id) {
          //   database.app.put(response.data.data.session_id, 'sessionId');
          // }

          return resolve(raw ? response.data : response.data.data);
        });
      })
      .catch(function(error) {
        switch (error.response && error.response.status) {
          case 401:
            window.location.hash = '/logout';
            return Promise.reject();
          case 500:
            notificationObservable.next({
              key: 'general_error',
              type: 'error'
            });
            return Promise.reject();
          default:
            // if (error.response) {
            //   if (!error.response.data.errors && error.response.data.message) {
            //     const action = {
            //       type: 'NOTIFICATION',
            //       messageType: 'error',
            //       message: error.response.data.message
            //     };
            //
            //     database.app.put(action, 'dispatchAction');
            //   }
            //
            //   return Promise.reject(error.response.data);
            // } else {
            //   database.app.put(
            //     {
            //       type: 'NOTIFICATION',
            //       messageKey: 'general_error',
            //       messageType: 'error'
            //     },
            //     'dispatchAction'
            //   );
            //   window.location.hash = '/logout';
            //   return Promise.reject();
            // }

            if (error.response) {
              return Promise.reject(error.response.data);
            } else {
              return Promise.reject();
            }
        }
      });
  }
}
