import Requester from './Requester';
import database from './database';

import { notificationObservable } from '../containers/NotificationMessagesContainer';

class Common {
  static getCountries(language) {
    return Requester.fetch({
      sync: true,
      params: {
        action: 'countries',
        initial: 1,
        lang: language
      }
    }).then(({ Country }) => {
      if (Country.server_created.length) {
        return Country.server_created.map(item => item.Country);
      } else {
        // Rollback to EN
        return this.getCountries('en-GB');
      }
    });
  }

  static getTranslations(language) {
    // Fetch the list of the available supported languages
    return Requester.fetch({
      sync: true,
      params: {
        action: 'translations'
      }
    }).then(({ Translation }) => {
      let fileURL = '';

      Translation.server_created.forEach(item => {
        if (item.name === language) {
          fileURL = item.url;
        }
      });

      // Fetch the XML of the translations of the language
      return Requester.fetch({
        url: fileURL,
        withoutHeaders: false,
        method: 'post',
        isXMLResponse: true
      }).then(({ resources }) => {
        const messages = {};

        resources.string.forEach(item => {
          const key = (item.$.name || '').toLowerCase();
          messages[key] = item._ || key;
        });

        return messages;
      });
    });
  }

  static async getExercises(lang) {
    return Requester.fetch({
      params: {
        action: 'exercises',
        initial: 1,
        lang
      },
      sync: true
    }).then(({ Exercise }) => {
      const limbArr = [];
      const articulationArr = [];
      const muscleArr = [];
      const exerciseArr = [];
      const exerciseTabArr = [];
      const exerciseContentArr = [];

      Exercise.exercises.forEach(limb => {
        limb.Articulation.forEach(articulation => {
          articulation.Muscle.forEach(muscle => {
            muscle.Exercise.forEach(exercise => {
              exercise.ExerciseTab.forEach(exerciseTab => {
                exerciseTab.ExerciseContent.map(item => {
                  return exerciseContentArr.push(item.ExerciseTypeContent);
                });

                delete exerciseTab.ExerciseContent;
                exerciseTabArr.push(exerciseTab);
              });

              delete exercise.ExerciseTab;
              exerciseArr.push(exercise);
            });

            delete muscle.Exercise;
            muscleArr.push(muscle);
          });

          delete articulation.Muscle;
          articulationArr.push(articulation);
        });

        delete limb.Articulation;
        limbArr.push(limb.Limb);
      });

      return Promise.all([
        database.contentTypes.bulkPut(Exercise.content_types),
        database.exerciseTypes.bulkPut(Exercise.exercise_types),
        database.limb.bulkPut(limbArr),
        database.articulation.bulkPut(articulationArr),
        database.muscle.bulkPut(muscleArr),
        database.exercise.bulkPut(exerciseArr),
        database.exerciseTab.bulkPut(exerciseTabArr),
        database.exerciseContent.bulkPut(exerciseContentArr)
      ]).catch(() => {
        notificationObservable.next({
          key: 'general_error',
          type: 'error'
        });

        window.location.hash = '/logout';

        return Promise.reject();
      });
    });
  }

  static getNewToken() {
    return Requester.fetch({
      headers: {
        'X-API-KEY': process.env.REACT_APP_X_API_KEY
      },
      params: {
        action: 'token'
      }
    }).then(({ token }) => {
      localStorage.setItem('token', token);
      const now = new Date();
      const alive = new Date(now.setMonth(now.getMonth() + 5));
      localStorage.setItem('token-alive', alive.getTime());
    });
  }

  static async updateProfile(formData, tunnel_step) {
    const user = await database.app.get('user');
    const requestParams = {
      User: {
        tunnel_step
      },
      PatientProfile: {
        birthyear: formData.birthyear,
        gender: formData.gender,
        weight: formData.weight,
        size: formData.size,
        handedness: formData.handedness,
        city: formData.city,
        etiology: formData.etiology,
        months_since_trauma:
          Number(formData.years_since_trauma) * 12 + Number(formData.months_since_trauma),
        area: formData.area,
        paresis_side: formData.paresis_side,
        technical_assistance: formData.technical_assistance,
        has_botox: formData.has_botox,
        botox: formData.botox
      },
      Setting: {
        country_id: formData['country_id']
      }
    };

    user.PatientProfile = user.PatientProfile || {};

    if (formData.etiology === 'etiology_stroke') {
      requestParams.PatientProfile.etiology_stroke_recurrence =
        formData['etiology_stroke_recurrence'];
      requestParams.PatientProfile.etiology_stroke_type = formData['etiology_stroke_type'];
    }

    if (formData.technical_assistance !== '0') {
      requestParams.PatientProfile.technical_assistance_name =
        formData['technical_assistance_name'];
    }

    return Requester.fetch({
      params: {
        action: 'my_patient_profile'
      },
      data: requestParams
    })
      .then(() => {
        // Reformat data to saving locally
        user.PatientProfile.birthyear_min = formData.birthyear.split(' - ')[0];
        user.PatientProfile.birthyear_max = formData.birthyear.split(' - ')[1];

        if (formData.etiology === 'etiology_stroke') {
          user.PatientProfile.etiology = `etiology_stroke_
           ${formData.etiology_stroke_recurrence}_${formData.etiology_stroke_type}`;

          delete formData.etiology;
          delete formData.etiology_stroke_recurrence;
          delete formData.etiology_stroke_type;
        }

        if (formData.technical_assistance !== '0') {
          user.PatientProfile.technical_assistance = formData.technical_assistance_name;

          delete formData.technical_assistance;
          delete formData.technical_assistance_name;
        }

        user.PatientProfile.months_since_trauma =
          Number(formData.years_since_trauma) * 12 + Number(formData.months_since_trauma);

        delete formData.birthyear;
        delete formData.years_since_trauma;
        delete formData.months_since_trauma;

        Object.assign(user.PatientProfile, formData);

        return database.app.put(user, 'user');
      })
      .catch(({ errors }) => {
        return Promise.reject(errors.PatientProfile);
      });
  }

  static async updateNotificationSettings(formData) {
    const user = await database.app.get('user');

    // Removes hour data when frequency is never
    if (
      formData.settings_options_notification_frequency ===
      'settings_options_notification_frequency_never'
    ) {
      formData.settings_options_notification_frequency_hour = null;
    } else {
      // Concats hours and minutes
      formData.settings_options_notification_frequency_hour +=
        ':' + formData.settings_options_notification_frequency_minute;
    }

    // Useless query data
    delete formData.settings_options_notification_frequency_minute;

    // Keeps day data when frequency is weekly
    if (
      formData.settings_options_notification_frequency !==
      'settings_options_notification_frequency_weekly'
    ) {
      formData.settings_options_notification_frequency_day = null;
    }

    return Requester.fetch({
      params: {
        action: 'my_options'
      },
      data: {
        Setting: {
          ...formData
        }
      }
    })
      .then(({ Setting }) => {
        Object.assign(user.Setting, Setting);

        return database.app.put(user, 'user');
      })
      .catch(({ errors }) => {
        return Promise.reject(errors.Setting);
      });
  }
}

export default Common;
