import React, { Component } from 'react';
import { Subject } from 'rxjs/Subject';

import NotificationMessages from '../components/NotificationMessages';

export const notificationObservable = new Subject();

export default class NotificationMessagesContainer extends Component {
  state = {
    message: null
  };

  componentWillMount() {
    notificationObservable.subscribe({
      next: ({ type, key }) => {
        this.setState({
          message: {
            type,
            messageKey: key
          }
        });

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.setState({ message: null });
        }, 6000);
      }
    });
  }

  render() {
    const message = this.state.message;

    if (!message) return null;

    return (
      Boolean(message) && (
        <NotificationMessages
          key={window.performance.now()}
          {...this.props}
          {...message}
        />
      )
    );
  }
}
